export const scrollToSectionMixin = {
  methods: {
    scrollToSection(sectionId, headerOffset = 80) {
      this.$nextTick(() => {
        const targetSection = document.getElementById(sectionId)
        if (targetSection) {
          if (this.$store && this.$store.getters['menu/menu']) {
            this.$store.commit('menu/menu', false)
          }

          const elementPosition = targetSection.getBoundingClientRect().top
          const offsetPosition = elementPosition + window.scrollY - headerOffset

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          })
        }
      })
    },
    checkAndScrollToHashSection(headerOffset = 80) {
      const hash = window.location.hash.slice(1)
      if (hash) {
        this.scrollToSection(hash, headerOffset)
      }
    }
  }
}
