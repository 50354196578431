<template>
  <div class="transs-wr">
    <nuxt />
  </div>
</template>

<script>

export default {
};
</script>
