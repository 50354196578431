<template>
  <div class="popup popup-personal-room popup-open modal-mask">
    <div class="modal-wrapper">
      <div class="popup-close-bloc popup-close-bloc-personal-room close-popup-btn" @click.self="$emit('close')" />
      <div class="popup-wr-item auth-card">
        <div class="modal-header">
          <h5 class="auth-title">
            {{ reset ? $t('modals.login.password_recovery') : $t('modals.login.login') }}
          </h5>
          <span class="popup-close-btn popup-close-btn-personal-room" @click.self="$emit('close')">&times;</span>
        </div>

        <transition name="faded">
          <div v-if="!reset && !auth && showComponent === 'login'" class="auth-content">
            <h3 class="auth-subtitle">Account</h3>
            <form action="" class="auth-form">
              <div class="form-group">
                <label>
                  <p v-if="getRequestStateUser('login', 'isRejected')" class="error-text">
                    {{ errStep }}
                  </p>

                  <div class="input-wrapper">
                    <input
                      v-validate="'required|email'"
                      data-vv-scope="login"
                      v-model="formAuth.email"
                      :class="{ error: errors.has('login.email') }"
                      :placeholder="$t('modals.login.email')"
                      name="email"
                      type="email"
                      class="auth-input"
                      @keyup.enter="$refs.password.focus()" />
                  </div>
                </label>
              </div>

              <div class="form-group">
                <label>
                  <div class="input-wrapper">
                    <input
                      ref="password"
                      v-validate="'required|min:1'"
                      data-vv-scope="login"
                      v-model="formAuth.password"
                      :class="{ error: errors.has('login.password') }"
                      name="password"
                      type="password"
                      placeholder="Password"
                      class="auth-input"
                      @keyup.enter="
                        () => {
                          if (!captchaSettings.sitekey || !captchaSettings.module) return signin({ formAuth })
                        }
                      " />
                  </div>
                </label>
              </div>

              <div v-if="captchaSettings.sitekey && captchaSettings.module === 'recaptcha_v2'" class="captcha-wrapper">
                <recaptcha
                  ref="recaptcha"
                  :sitekey="captchaSettings.sitekey"
                  :load-recaptcha-script="true"
                  :language="$root.$i18n.locale"
                  @verify="(token) => (formAuth.captcha = token)" />
              </div>

              <div class="text-center">
                <button v-if="getRequestStateUser('login', 'isPending')" class="auth-button" type="button">
                  <loader :responsive="true" width="44" height="44" />
                </button>
                <button v-else class="auth-button" type="button" @click="signin({ formAuth })">
                  <span>{{ $t('ui.buttons.sign_in') }}</span>
                </button>

                <div class="sign-up-wrapper">
                  <a
                    class="button"
                    @click="
                      showLog(false)
                      showReg(true)
                    ">
                    {{ $t('ui.buttons.sign_up') }}
                  </a>
                </div>
                <div class="div-for-line"></div>

                <div class="forgot-password-wrapper">
                  <a @click="reset = true">{{ $t('ui.buttons.forget') }}</a>
                </div>
              </div>
            </form>
          </div>

          <div v-if="reset && !auth" class="popup-top popup-specific-class">
            <form action="">
              <div>
                <label class="popup-succes margin-b-0">
                  <span>{{ $t('modals.login.email') }}</span>
                  <p class="input-img">
                    <img src="~assets/img/mail.svg" alt="" />
                    <input v-model="email" type="email" placeholder="example@mail.com" />
                  </p>
                </label>
              </div>
              <div
                v-if="captchaSettings.sitekey && captchaSettings.module === 'recaptcha_v2'"
                style="transform: scale(0.9); margin-left: -15px; margin-bottom: 0">
                <recaptcha
                  ref="recaptcha"
                  :sitekey="captchaSettings.sitekey"
                  :load-recaptcha-script="true"
                  :language="$root.$i18n.locale"
                  @verify="(token) => (captcha = token)" />
              </div>

              <div class="text-center">
                <button class="btn btn-submit reset-pass" @click.prevent="restPassword({ email, captcha })">
                  {{ $t('ui.buttons.password_recovery') }}
                </button>
              </div>
            </form>
          </div>
        </transition>
        <div v-if="showComponent === '2fa'" class="popup-top">
          <p
            v-if="getRequestStateUser('login', 'isRejected')"
            :style="{ color: 'red', textAlign: 'center', fontWeight: 'bold' }">
            {{ errStep2 }}
          </p>
          <form action="">
            <div>
              <p class="d-flex flex-start">
                <img src="~assets/img/locked.svg" alt="" />{{ $t('modals.2fa.confirmCode') }}
              </p>
              <input
                ref="number"
                v-model="formAuth.code"
                autofocus
                class="hide"
                type="number"
                name="code"
                @keyup.enter="$emit('confirm', formAuth.code)"
                @input="
                  $event.target.value.length < 7
                    ? (formAuth.code = $event.target.value)
                    : (formAuth.code = $event.target.value.slice(0, 6))
                " />
              <div class="d-flex flex-row margin-b-0">
                <div
                  v-for="(val, i) in 6"
                  :key="i"
                  class="number"
                  :class="formAuth.code.length === i ? '' : ''"
                  @click="$refs.number.focus()">
                  {{ formAuth.code.length === i ? '' : formAuth.code.charAt(i) }}
                  <span v-if="formAuth.code.length === i || (i === 5 && formAuth.code.length === 6)" class="flick"
                    >|</span
                  >
                </div>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-submit" @click.prevent="signin({ step: '2fa', formAuth })">
                {{ $t('ui.buttons.sign_in') }}
              </button>
            </div>
          </form>
        </div>
        <div v-if="getRequestStateSocial('getSocials', 'isRejected')" class="popup-bottom d-flex">
          <p class="popup-bottom-title text-center">Error loading social methods</p>
        </div>

        <!-- <div v-else-if="getRequestStateSocial('getSocials', 'isPending')" class="popup-bottom d-flex">
          <p class="popup-bottom-title text-center">Loading social methods...</p>
        </div> -->
        <div
          v-else-if="!reset && !auth && getRequestStateSocial('getSocials', 'isSuccess') && socials.data.length > 0"
          class="popup-bottom">
          <p class="popup-bottom-title">
            {{ $t('modals.login.social') }}
          </p>
          <ul class="social-list">
            <li v-for="(social, key) in socials.data" :key="'social_btn_' + key">
              <a :href="$rest.servicePath + social.url" class="social-link">
                <img v-if="social.name === 'google'" src="~assets/img/social/google-plus.svg" alt="" />
                <img v-if="social.name === 'facebook'" src="~assets/img/social/facebook.svg" alt="" />
                <img v-if="social.name === 'apple'" src="~assets/img/social/apple.svg" alt="" />
                <img v-if="social.name === 'twitter'" src="~assets/img/social/twitter.svg" alt="" />
                <img v-if="social.name === 'github'" src="~assets/img/social/github.svg" alt="" />
                <img v-if="social.name === 'linkedin'" src="~assets/img/social/linkedin.svg" alt="" />
                <img v-if="social.name === 'meetup'" src="~assets/img/social/meetup.svg" alt="" />
                <img v-if="social.name === 'instagram'" src="~assets/img/social/instagram.svg" alt="" />
                <img v-if="social.name === 'foursquare'" src="~assets/img/social/foursquare.svg" alt="" />
                <img v-if="social.name === 'amazon'" src="~assets/img/social/amazon.svg" alt="" />
                <img v-if="social.name === 'tumblr'" src="~assets/img/social/tumblr.svg" alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      reset: false,
      email: '',
      captcha: '',
      auth: false,
      valid: true,
      validCode: true,
      errStep: '',
      errStep2: '',
      message: '',
      showComponent: 'login',

      formAuth: {
        email: '',
        password: '',
        captcha: '',
        code: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      captchaSettings: 'params/getCaptchaConf',
      socials: 'social/getSocials',
      getRequestStateSocial: 'social/getRequestState',
      getRequestStateUser: 'user/getRequestState',
      modalLoginType: 'user/login'
    })
  },
  mounted() {
    return this.updateSocials()
  },
  methods: {
    ...mapActions({
      updateSocials: 'social/updateSocials'
    }),
    ...mapActions({ login: 'user/login', restPasswordAPI: 'user/restPassword' }),
    ...mapMutations({
      showLog: 'user/login',
      showReg: 'user/reg',
      changeStatusOpenKycModal: 'user/changeStatusOpenKycModal'
    }),
    async restPassword(data) {
      await this.restPasswordAPI(data)
      if (this.getRequestStateUser('restPassword', 'error').errorCode === 1601588666628861) {
        if (this.$refs.recaptcha && this.$refs.recaptcha.reset) this.$refs.recaptcha.reset()
      }
    },
    async signin(el) {
      if (!(await this.$validator.validateAll('login'))) return
      if (el.step !== '2fa') {
        if (!el.formAuth.captcha && this.captchaSettings.sitekey && this.captchaSettings.module) {
          this.$swal('', this.$t('errorMessage.1601588666628861'), 'error')
          return
        }
      }

      let apiResult = await this.login(el.formAuth)
      if (this.getRequestStateUser('login', 'error').errorCode === 1601588666628861) {
        if (this.$refs.recaptcha && this.$refs.recaptcha.reset) this.$refs.recaptcha.reset()
      }
      if (this.getRequestStateUser('login', 'error').errorCode !== 6231533598118172) {
        this.errStep = this.getRequestStateUser('login', 'error').message
      }
      if (el.step === '2fa') {
        this.errStep2 = this.getRequestStateUser('login', 'error').message
      }
      if (this.getRequestStateUser('login', 'error').errorCode === 6231533598118172) {
        this.errStep = undefined
        if (this.showComponent === '2fa') {
          return (this.valid = false)
        }
        this.showComponent = '2fa'
        process.nextTick(() => {
          this.$refs.number.focus()
        })
        return
      }
      if (apiResult) {
        this.$store.commit('user/setAuth', apiResult)
        this.errStep2 = undefined
        return null
      }
    }
  }
}
</script>
<style scoped>
.auth-card {
  border-radius: 9px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  background: linear-gradient(0deg, #0f0f11 0%, #19191b 100%);

  padding: 20px 10px;
  color: #fff;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6%;
}

.auth-title {
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 30px;
  letter-spacing: 4.5px;
  text-align: center;
  font-weight: 300;
  margin: 0;
  padding: 1rem;
}

.auth-subtitle {
  color: #00a7f8;
  text-shadow: 0 0 50px #00a7f8;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 4.5px;
  text-align: center;
  margin: 15px 0 0;
}

.auth-content {
  width: 90%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-form {
  width: 100%;
}

.auth-input {
  width: 100%;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: rgba(15, 15, 17, 1);
  /* padding: 24px 47px; */
  color: #fff;
  border: none;
  font-size: 18px;
}

.auth-input.error {
  border: 1px solid red;
}

.auth-button {
  border-radius: 10px;
  background: #00a7f8;
  margin-top: 40px;
  width: 275px;
  max-width: 100%;
  color: rgba(15, 15, 17, 1);
  font-weight: 700;
  padding: 24px 47px;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.divider {
  width: 100%;
  height: 1px;
  border: 1px solid #00a7f8;
  margin: 39px 0;
}

.error-text {
  color: red;
  text-align: center;
  font-weight: bold;
}

.form-group {
  width: 100%;
  margin-top: 25px;
}

.captcha-wrapper {
  transform: scale(0.9);
  margin-left: -15px;
  margin-bottom: 0;
}

.input-wrapper {
  input {
    background: #0f0f11;
  }
}

.sign-up-wrapper {
  margin-top: 30px;
  a {
    color: #00a7f8;
    font-size: 18px;
    font-weight: 300;
    line-height: 21.09px;
    letter-spacing: 0.15em;
    text-align: center;
    text-decoration-skip-ink: none;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
  }
}

.forgot-password-wrapper {
  a {
    font-size: 18px;
    font-weight: 300;
    line-height: 21.09px;
    letter-spacing: 0.15em;
    text-align: center;
    text-decoration-skip-ink: none;
    box-shadow: 0px 4px 4px 0px #00000040;
    color: #00a7f8;
    cursor: pointer;
  }
}

.div-for-line {
  width: 100%;
  border: 1px solid #00a7f8;
  margin-top: 30px;
  margin-bottom: 30px;
}

.popup-wr-item {
}

.popup-top {
  background-color: inherit;
  width: 60%;

  .popup-succes {
    width: 100%;
    input {
      background: #0c0f1280;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: 21.09px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      border-top: none;
    }
  }
  .reset-pass {
    border-radius: 10px;
    background: #00a7f8;
    color: rgba(15, 15, 17, 1);
    font-weight: 600;
    border: none;
    cursor: pointer;
    font-size: 18px;
    line-height: 2.8;
  }
}

.popup-specific-class {
  width: 100%;
}

@media (max-width: 991px) {
  .auth-card {
    padding: 20px;
  }

  .auth-input {
    padding: 16px 20px;
  }

  .auth-button {
    padding: 24px 20px;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 520px) {
  .auth-content {
    width: 75%;
  }
  .popup-specific-class {
    width: 75%;
  }
}

@media (min-width: 740px) {
  .auth-card {
    width: 80%;
  }

  .auth-content {
    width: 70%;
  }
}

@media (min-width: 740px) {
  .auth-card {
    width: 70%;
  }
}
@media (min-width: 1200px) {
  .auth-card {
    width: 60%;
  }

  .auth-content {
    width: 70%;
  }
}

@media (min-width: 1400px) {
  .auth-card {
    width: 35%;
  }

  .auth-content {
    width: 70%;
  }
}
</style>
