<template>
    <div class="footerContainer">
        <img src="../../assets/img/logo.svg" alt="exontus-logo" />
        <div class="footerContainer footerNav">
            <nuxt-link :to="localePath('/')">
                <span>Home</span>
            </nuxt-link>
            <nuxt-link :to="localePath('/')">
                <span>Features</span>
            </nuxt-link>
            <nuxt-link :to="localePath('/')">
                <span>Assets</span>
            </nuxt-link>
            <nuxt-link :to="localePath('/')">
                <span>FAQ</span>
            </nuxt-link>
            <nuxt-link :to="localePath('/')">
                <span>Protection</span>
            </nuxt-link>
        </div>
        <h2>Follow Us</h2>
        <div class="footerContainer footerSocials">
            <a href="#">
                <WhatsappIcon />
            </a>
            <a href="#">
                <FacebookIcon />
            </a>
            <a href="#">
                <YoutubeIcon />
            </a>
            <a href="#">
                <InstagramIcon />
            </a>
        </div>
    </div>

</template>

<script>
import FacebookIcon from '../../assets/reactive-svg/facebookIcon.vue';
import WhatsappIcon from '../../assets/reactive-svg/whatsappIcon.vue';
import YoutubeIcon from '../../assets/reactive-svg/youtubeIcon.vue';
import InstagramIcon from '../../assets/reactive-svg/instagramIcon.vue';

export default {
    components: {
        WhatsappIcon,
        FacebookIcon,
        YoutubeIcon,
        InstagramIcon
    }
}

</script>